import { UUID } from "@screencloud/uuid";
import request from "superagent";
import queryString from "query-string";
import { ScreenData } from "../ScreenCloudReactApp";
import { uuid } from "uuidv4";
import { appConfig } from "../appConfig";

export enum RendererStatus {
  Starting,
  Rendering,
  Error,
  InsufficientQuota,
  NotModified,
  None
}

export interface GetMediaRequest {
  siteId: UUID;
  screenId: UUID;
  screenData: ScreenData | null;
  viewportWidth: number;
  viewportHeight?: number;
  viewportScale: number;
  authToken: string;
  timeZone?: string;
  useViewportDimensions?: boolean;
  previousTimestamp?: number;
}

export interface QueryStringParams {
  screenData?: string;
  screenId: UUID;
  w: number;
  h?: number;
  s: number;
  tz: string;
  ts?: number;
}

export interface GetMediaResponse {
  rendererStatus: RendererStatus;
  jobError?: string;
  base64Image?: string;
  renderError?: string;
  renderTimestamp?: number;
}

const viewportDefaults = {
  fourKay: {
    width: 3840,
    height: 2160,
    scale: 1
  },
  hd: {
    width: 1920,
    height: 1080,
    scale: 1
  },
  seven20: {
    width: 1280,
    height: 720,
    scale: 1
  }
};

const JOB_ERROR_TOKEN = "GetJobError:";
const IMAGE_DATA_TOKEN = "data:";
const TIMEZONE_TOKEN = "ts=";
const FIELD_SEPARATOR = ";";
const MAX_RETRY_COUNT = 5;

/**
 * Retrieve screenshot timestamp from response
 * @param responseText response from screenshot fetch attempt
 * @returns timestamp or zero
 */
const getTimestamp = (responseText: string): number => {
  let timestamp = 0;

  const start = responseText.indexOf(TIMEZONE_TOKEN, 0);
  const end = responseText.indexOf(FIELD_SEPARATOR, start >= 0 ? start : 0);

  if (start > 0 && end > start + TIMEZONE_TOKEN.length) {
    const val = parseInt(
      responseText.substring(start + TIMEZONE_TOKEN.length, end)
    );
    timestamp = isNaN(val) ? 0 : val;
  }

  return timestamp;
};

/**
 * Retrieve job error from response
 * @param responseText response from screenshot fetch attempt
 * @returns job error or empty string
 */
const getJobError = (responseText: string): string => {
  let jobError = "";

  const start = responseText.indexOf(JOB_ERROR_TOKEN, 0);
  const end = responseText.indexOf(FIELD_SEPARATOR, start >= 0 ? start : 0);

  if (start >= 0 && end > start + JOB_ERROR_TOKEN.length) {
    jobError = responseText.substring(start + JOB_ERROR_TOKEN.length, end);
  }

  return jobError;
};

// Strip out error objects, if any, and return only image data
const getImageData = (responseText: string): string => {
  let imageData = "";

  // If image data is not present in the response string, indexOf will return -1
  if (responseText.indexOf(IMAGE_DATA_TOKEN) >= 0) {
    // Truncate everything before "data:"
    imageData = responseText.substring(
      responseText.indexOf(IMAGE_DATA_TOKEN),
      responseText.length
    );
  }

  return imageData;
};

const delay = (retryCount: number) =>
  new Promise(resolve =>
    setTimeout(resolve, 5000 * Math.round(1.618 ** retryCount))
  );

export const getRenderedMedia = async (
  getMediaRequest: GetMediaRequest,
  retryCount?: number
): Promise<GetMediaResponse> => {
  const mediaGatewayURL = `${appConfig.mediaGatewayBaseUrl}/v1/sites/${getMediaRequest.siteId}/snapshot`;

  if (!!!retryCount) {
    retryCount = 1;
  } else {
    console.debug(`getRenderedMedia: Error retry loop - Attempt ${retryCount}`);
  }

  const timeZone = getMediaRequest.timeZone ? getMediaRequest.timeZone : "UTC";

  let defaultViewport = viewportDefaults.hd;
  if(getMediaRequest.screenData && getMediaRequest.screenData["useUHD"] && getMediaRequest.screenData["useUHD"].toLowerCase() === "true"){
    defaultViewport = viewportDefaults.fourKay;
  }

  const queryStringParams: QueryStringParams = {
    screenId: getMediaRequest.screenId,
    w: defaultViewport.width,
    h: defaultViewport.height,
    s: defaultViewport.scale,
    tz: timeZone
  };

  if (getMediaRequest.useViewportDimensions) {
    queryStringParams.w = getMediaRequest.viewportWidth;
    queryStringParams.h = getMediaRequest.viewportHeight;
    queryStringParams.s = getMediaRequest.viewportScale;
  }

  if (getMediaRequest.screenData) {
    const screenDataBase64 = Buffer.from(
      JSON.stringify(getMediaRequest.screenData)
    ).toString("base64");
    queryStringParams.screenData = screenDataBase64;
  }

  if (getMediaRequest.previousTimestamp) {
    queryStringParams.ts = getMediaRequest.previousTimestamp;
  }

  const fullURL = `${mediaGatewayURL}?${queryString.stringify(
    queryStringParams
  )}`;

  let response: GetMediaResponse = {
    rendererStatus: RendererStatus.None
  };

  try {
    const renderingResponse = await request
      .get(fullURL)
      .set({
        Authorization: `Bearer ${getMediaRequest.authToken}`,
        "X-Tracking-Id": uuid()
      })
      .ok(res => res.status < 400) // Don't automatically treat 3xx as an error
      .timeout(60000);

    if (renderingResponse.status === 204) {
      response.rendererStatus = RendererStatus.Starting;
    } else if (renderingResponse.status === 200) {
      response.rendererStatus = RendererStatus.Rendering;
    } else if (renderingResponse.status === 304) {
      // If snapshot hasn't changed since last time, set previous successful timestamp in repsonse
      // Used for check in SecureSite when deciding to display "last updated" message
      response.renderTimestamp = getMediaRequest.previousTimestamp;
      response.rendererStatus = RendererStatus.NotModified;
    } else {
      response.rendererStatus = RendererStatus.Error;
      response.renderError = renderingResponse.text;
    }

    if (renderingResponse.text?.length > 0) {
      response.jobError = getJobError(renderingResponse.text);
      response.base64Image = getImageData(renderingResponse.text);
      response.renderTimestamp = getTimestamp(renderingResponse.text);
    }
  } catch (error) {
    if (
      error.status === 429 ||
      (error as Error).message.includes("Too Many Requests")
    ) {
      response.rendererStatus = RendererStatus.InsufficientQuota;
    } else {
      if (retryCount > MAX_RETRY_COUNT) {
        response.rendererStatus = RendererStatus.Error;
        response.renderError = error.response
          ? error.response.text
          : `An unknown error occurred with status code ${error.status}`;
      } else {
        await delay(retryCount);
        response = await getRenderedMedia(getMediaRequest, ++retryCount);
      }
    }
  }

  return response;
};
